import type {DateTime} from 'luxon';
import type {Scalars} from '~/graphql/graphql';

export type DateInput = Date | DateTime | string;

export type Email = string;

export type PhoneNumber = string;

export type Zipcode = string;

export enum Roles {
  Lid = 2,
  Medewerker = 3,
  Redacteur = 4,
  Beheerder = 5,
  Developer = 6,
}

export interface DatabaseComment {
  hash?: string;
  comment: string;
  created_at: string;
}

export interface TimelineComment {
  header?: string|null;
  createdAt: string;
  comment: string;
  attachment: Scalars['File'];
  hash?: string;
}
