import revive_payload_client_PW2hoXLrzj from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._qq3i2pycoqd2mahik6t2ysz5tu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GcGKApZrsq from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._qq3i2pycoqd2mahik6t2ysz5tu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_T9WX25Pfmd from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._qq3i2pycoqd2mahik6t2ysz5tu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_edusmP2Un8 from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.5_rollup@4.21.1_vite@5.4.2_@types+node@22.5.1_terser@5.3_jwzs2bfi7x4prvu7sar6ebmfkm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_iI0bvWok4W from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._qq3i2pycoqd2mahik6t2ysz5tu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6zWBQX7twy from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._qq3i2pycoqd2mahik6t2ysz5tu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IvqG6a6Dgz from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._qq3i2pycoqd2mahik6t2ysz5tu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_HwTeBnickk from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._qq3i2pycoqd2mahik6t2ysz5tu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_yiHQP2KrzM from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._qq3i2pycoqd2mahik6t2ysz5tu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jBAy4GEZ6M from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.21.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_zGGu6bPqUo from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.1_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_dPIi8oeSBy from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.1_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_Ui5UzX20h2 from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_graphql-ws@5.16.0_graphql@16.9.0__react-dom@18.3.1_react@_qv4lbigd632mzkti7dfptffi54/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_lAY7Mz4B6s from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.1_encoding@0.1.13_magicast@0.3.5_next-auth@4.21.1_next@13.5.6_@babel+_2mqfur7cquz2ovppw3cwonrfde/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/.nuxt/floating-vue.mjs";
import plugin_zhtXnH8Eij from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node_j64ma4vwn7vkpbxmnid2xl7lzm/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import errors_RL0uEiZWBo from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_cmy33any4nwxrebmui65663pre/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_KZTy4cAHsk from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_cmy33any4nwxrebmui65663pre/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_j2qLC4etcx from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_ujhozsuzekdyafrc3sjrc4j5bu/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_O1mln643eh from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_ujhozsuzekdyafrc3sjrc4j5bu/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_7JUI3v6AWs from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_esbuild@0.23.1_gr_dx5mtvp2spagd5ykzknag2krey/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_Y8GBeNope4 from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_esbuild@0.23.1_gr_dx5mtvp2spagd5ykzknag2krey/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import api_GFfDXud5Cr from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/plugins/api.ts";
import apollo_1ILFkzpYSr from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/plugins/apollo.ts";
import vue3_toastify_OGYNDsiW9E from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_PW2hoXLrzj,
  unhead_GcGKApZrsq,
  router_T9WX25Pfmd,
  _0_siteConfig_edusmP2Un8,
  payload_client_iI0bvWok4W,
  navigation_repaint_client_6zWBQX7twy,
  check_outdated_build_client_IvqG6a6Dgz,
  chunk_reload_client_HwTeBnickk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yiHQP2KrzM,
  plugin_jBAy4GEZ6M,
  switch_locale_path_ssr_zGGu6bPqUo,
  i18n_dPIi8oeSBy,
  formkitPlugin_pZqjah0RUG,
  plugin_Ui5UzX20h2,
  plugin_lAY7Mz4B6s,
  floating_vue_EIcJ7xiw0h,
  plugin_zhtXnH8Eij,
  errors_RL0uEiZWBo,
  i18n_KZTy4cAHsk,
  apollo_j2qLC4etcx,
  sofie_required_version_O1mln643eh,
  apollo_7JUI3v6AWs,
  auth_Y8GBeNope4,
  api_GFfDXud5Cr,
  apollo_1ILFkzpYSr,
  vue3_toastify_OGYNDsiW9E
]