import { default as _91_46_46_46slug_935VP02qyO4nMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/[...slug].vue?macro=true";
import { default as adressenqdJ07uf92wMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/bedrijfsgegevens/[company]/adressen.vue?macro=true";
import { default as indexIubb2OzWkOMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/bedrijfsgegevens/[company]/index.vue?macro=true";
import { default as medewerkersd6Spl2xGCsMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/bedrijfsgegevens/[company]/medewerkers.vue?macro=true";
import { default as indexpaWw9AglEAMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/bedrijfsgegevens/index.vue?macro=true";
import { default as indexrrRAL1Z9AcMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/communicatievoorkeuren/index.vue?macro=true";
import { default as indexHbFdl0xwgVMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/evenementen/index.vue?macro=true";
import { default as _91factuur_93tErfrqVpVhMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/facturen/[factuur].vue?macro=true";
import { default as indexpckIiJdXYRMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/facturen/index.vue?macro=true";
import { default as indexTIRSA4VTwXMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/index.vue?macro=true";
import { default as indexuZqGwem6jVMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/ledenvoordelen/index.vue?macro=true";
import { default as incassoQJnbVKtxKoMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue?macro=true";
import { default as index1ycyeO4QGEMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/lidmaatschap/[lidmaatschap]/index.vue?macro=true";
import { default as indexyZ9Xf7ZgJDMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/lidmaatschap/index.vue?macro=true";
import { default as nieuwsbriefvoorkeurenfJw3I7MjODMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/nieuwsbriefvoorkeuren.vue?macro=true";
import { default as accountQ5urIew4KIMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account.vue?macro=true";
import { default as loginFqsBL1VrpCMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/auth/login.vue?macro=true";
import { default as _91token_93hJJbtBJ1O5Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91token_93Ok8jteT62BMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/auth/wachtwoord-vergeten/[token].vue?macro=true";
import { default as index7VrO0xfBCfMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/auth/wachtwoord-vergeten/index.vue?macro=true";
import { default as _91slug_93J6jDIDQII4Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/bestuurders/[slug].vue?macro=true";
import { default as bestuurdersacademievuNBfFiIcfMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/bestuurders/bestuurdersacademie.vue?macro=true";
import { default as dossiers_45documentenwUh2V6us3zMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/bestuurders/dossiers-documenten.vue?macro=true";
import { default as indexY0i7nBhW7MMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/bestuurders/index.vue?macro=true";
import { default as bestuurdersQbwJkDGRC1Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/bestuurders.vue?macro=true";
import { default as dashboardoIKl6heB7MMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/dashboard.vue?macro=true";
import { default as aanmeldenRYHOtHQJJhMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/evenementen/[slug]/aanmelden.vue?macro=true";
import { default as indexP3CJATftAVMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/evenementen/[slug]/index.vue?macro=true";
import { default as indexgbCcElKL8VMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/evenementen/index.vue?macro=true";
import { default as evenementenBtXnELov3rMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/evenementen.vue?macro=true";
import { default as indexbolQQM6oFaMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/index.vue?macro=true";
import { default as kennisbankRJ0paWpVCbMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/kennisbank.vue?macro=true";
import { default as _91id_933J32bZZU0AMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/klanten/[id].vue?macro=true";
import { default as indexqxqRQrNzviMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/klanten/index.vue?macro=true";
import { default as afnemenphAHMBfrwPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/ledenvoordelen/[slug]/afnemen.vue?macro=true";
import { default as index7T2yNnLVgyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/ledenvoordelen/[slug]/index.vue?macro=true";
import { default as indexXfFXa7gYQ0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/ledenvoordelen/index.vue?macro=true";
import { default as ledenvoordelenuO684P3gzwMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/ledenvoordelen.vue?macro=true";
import { default as indexE6kYWI73T0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/lid-worden/index.vue?macro=true";
import { default as uitschrijvenUIsyPA56PZMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/nieuwsbrief/uitschrijven.vue?macro=true";
import { default as authorizeLCfwozacwoMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/oauth/authorize.vue?macro=true";
import { default as stylesiy3iXTsxk4Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/styles.vue?macro=true";
import { default as _91id_93YpfOv5IDCyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/verkoopkansen/[id].vue?macro=true";
import { default as indexXCosWBaQMdMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/verkoopkansen/index.vue?macro=true";
import { default as _91product_93JEETAkSS8QMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/[category]/[product].vue?macro=true";
import { default as indexTNBzrl2ShyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/[category]/index.vue?macro=true";
import { default as cartdpibngiiuoMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/cart.vue?macro=true";
import { default as checkoutW6Dfy71rDSMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/checkout.vue?macro=true";
import { default as confirmationLIklPH2l5uMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/confirmation.vue?macro=true";
import { default as index5jVGVNIviMMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/index.vue?macro=true";
import { default as webshopu72Xw5RUkiMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop.vue?macro=true";
import { default as zoekena6rrP4zPpOMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/zoeken.vue?macro=true";
import { default as indexD704OlaQYNMeta } from "~/pages/evenementen/[slug]/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_935VP02qyO4nMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: accountQ5urIew4KIMeta?.name,
    path: "/account",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-bedrijfsgegevens-company-adressen",
    path: "bedrijfsgegevens/:company()/adressen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/bedrijfsgegevens/[company]/adressen.vue").then(m => m.default || m)
  },
  {
    name: "account-bedrijfsgegevens-company",
    path: "bedrijfsgegevens/:company()",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/bedrijfsgegevens/[company]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bedrijfsgegevens-company-medewerkers",
    path: "bedrijfsgegevens/:company()/medewerkers",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/bedrijfsgegevens/[company]/medewerkers.vue").then(m => m.default || m)
  },
  {
    name: "account-bedrijfsgegevens",
    path: "bedrijfsgegevens",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/bedrijfsgegevens/index.vue").then(m => m.default || m)
  },
  {
    name: "account-communicatievoorkeuren",
    path: "communicatievoorkeuren",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/communicatievoorkeuren/index.vue").then(m => m.default || m)
  },
  {
    name: "account-evenementen",
    path: "evenementen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/evenementen/index.vue").then(m => m.default || m)
  },
  {
    name: "account-facturen-factuur",
    path: "facturen/:factuur()",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/facturen/[factuur].vue").then(m => m.default || m)
  },
  {
    name: "account-facturen",
    path: "facturen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/facturen/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    meta: indexTIRSA4VTwXMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-ledenvoordelen",
    path: "ledenvoordelen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/ledenvoordelen/index.vue").then(m => m.default || m)
  },
  {
    name: "account-lidmaatschap-lidmaatschap-incasso",
    path: "lidmaatschap/:lidmaatschap()/incasso",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue").then(m => m.default || m)
  },
  {
    name: "account-lidmaatschap-lidmaatschap",
    path: "lidmaatschap/:lidmaatschap()",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/lidmaatschap/[lidmaatschap]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-lidmaatschap",
    path: "lidmaatschap",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/lidmaatschap/index.vue").then(m => m.default || m)
  },
  {
    name: "account-nieuwsbriefvoorkeuren",
    path: "nieuwsbriefvoorkeuren",
    meta: nieuwsbriefvoorkeurenfJw3I7MjODMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/account/nieuwsbriefvoorkeuren.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginFqsBL1VrpCMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-wachtwoord-instellen-token",
    path: "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93hJJbtBJ1O5Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/auth/wachtwoord-instellen/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-wachtwoord-vergeten-token",
    path: "/auth/wachtwoord-vergeten/:token()",
    meta: _91token_93Ok8jteT62BMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/auth/wachtwoord-vergeten/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-wachtwoord-vergeten",
    path: "/auth/wachtwoord-vergeten",
    meta: index7VrO0xfBCfMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/auth/wachtwoord-vergeten/index.vue").then(m => m.default || m)
  },
  {
    name: bestuurdersQbwJkDGRC1Meta?.name,
    path: "/bestuurders",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/bestuurders.vue").then(m => m.default || m),
    children: [
  {
    name: "bestuurders-slug",
    path: ":slug()",
    meta: _91slug_93J6jDIDQII4Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/bestuurders/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bestuurders-bestuurdersacademie",
    path: "bestuurdersacademie",
    meta: bestuurdersacademievuNBfFiIcfMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/bestuurders/bestuurdersacademie.vue").then(m => m.default || m)
  },
  {
    name: "bestuurders-dossiers-documenten",
    path: "dossiers-documenten",
    meta: dossiers_45documentenwUh2V6us3zMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/bestuurders/dossiers-documenten.vue").then(m => m.default || m)
  },
  {
    name: "bestuurders",
    path: "",
    meta: indexY0i7nBhW7MMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/bestuurders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: evenementenBtXnELov3rMeta?.name,
    path: "/evenementen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/evenementen.vue").then(m => m.default || m),
    children: [
  {
    name: "evenementen-slug-aanmelden",
    path: ":slug()/aanmelden",
    meta: aanmeldenRYHOtHQJJhMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/evenementen/[slug]/aanmelden.vue").then(m => m.default || m)
  },
  {
    name: "evenementen-slug",
    path: ":slug()",
    meta: indexP3CJATftAVMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/evenementen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "evenementen",
    path: "",
    meta: indexgbCcElKL8VMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/evenementen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexbolQQM6oFaMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kennisbank",
    path: "/kennisbank",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/kennisbank.vue").then(m => m.default || m)
  },
  {
    name: "klanten-id",
    path: "/klanten/:id()",
    meta: _91id_933J32bZZU0AMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/klanten/[id].vue").then(m => m.default || m)
  },
  {
    name: "klanten",
    path: "/klanten",
    meta: indexqxqRQrNzviMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/klanten/index.vue").then(m => m.default || m)
  },
  {
    name: ledenvoordelenuO684P3gzwMeta?.name,
    path: "/ledenvoordelen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/ledenvoordelen.vue").then(m => m.default || m),
    children: [
  {
    name: "ledenvoordelen-slug-afnemen",
    path: ":slug()/afnemen",
    meta: afnemenphAHMBfrwPMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/ledenvoordelen/[slug]/afnemen.vue").then(m => m.default || m)
  },
  {
    name: "ledenvoordelen-slug",
    path: ":slug()",
    meta: index7T2yNnLVgyMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/ledenvoordelen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "ledenvoordelen",
    path: "",
    meta: indexXfFXa7gYQ0Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/ledenvoordelen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "lid-worden",
    path: "/lid-worden",
    meta: indexE6kYWI73T0Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/lid-worden/index.vue").then(m => m.default || m)
  },
  {
    name: "nieuwsbrief-uitschrijven",
    path: "/nieuwsbrief/uitschrijven",
    meta: uitschrijvenUIsyPA56PZMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/nieuwsbrief/uitschrijven.vue").then(m => m.default || m)
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    meta: authorizeLCfwozacwoMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/oauth/authorize.vue").then(m => m.default || m)
  },
  {
    name: "styles",
    path: "/styles",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/styles.vue").then(m => m.default || m)
  },
  {
    name: "verkoopkansen-id",
    path: "/verkoopkansen/:id()",
    meta: _91id_93YpfOv5IDCyMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/verkoopkansen/[id].vue").then(m => m.default || m)
  },
  {
    name: "verkoopkansen",
    path: "/verkoopkansen",
    meta: indexXCosWBaQMdMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/verkoopkansen/index.vue").then(m => m.default || m)
  },
  {
    name: webshopu72Xw5RUkiMeta?.name,
    path: "/webshop",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop.vue").then(m => m.default || m),
    children: [
  {
    name: "webshop-category-product",
    path: ":category()/:product()",
    meta: _91product_93JEETAkSS8QMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/[category]/[product].vue").then(m => m.default || m)
  },
  {
    name: "webshop-category",
    path: ":category()",
    meta: indexTNBzrl2ShyMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "webshop-cart",
    path: "cart",
    meta: cartdpibngiiuoMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/cart.vue").then(m => m.default || m)
  },
  {
    name: "webshop-checkout",
    path: "checkout",
    meta: checkoutW6Dfy71rDSMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/checkout.vue").then(m => m.default || m)
  },
  {
    name: "webshop-confirmation",
    path: "confirmation",
    meta: confirmationLIklPH2l5uMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "webshop",
    path: "",
    meta: index5jVGVNIviMMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/webshop/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "zoeken",
    path: "/zoeken",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/52/nuxt/pages/zoeken.vue").then(m => m.default || m)
  },
  {
    name: "bestuurders-evenementen-slug",
    path: "/bestuurders/evenementen/:slug()",
    meta: indexD704OlaQYNMeta || {},
    component: () => import("~/pages/evenementen/[slug]/index.vue").then(m => m.default || m)
  }
]