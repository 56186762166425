<template>
  <FormKit
    v-model="addressForm"
    type="group"
    :ignore="true"
    @input="handleInput"
  >
    <div class="grid grid-cols-6">
      <div class="col-span-6 md:col-span-2">
        <FormKit
          label="Postcode"
          name="zipcode"
        />
      </div>
      <div class="col-span-3 md:col-span-2">
        <FormKit
          label="Huisnummer"
          name="house_number"
        />
      </div>
      <div class="col-span-3 md:col-span-2">
        <FormKit
          label="Toevoeging"
          name="house_number_suffix"
        />
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div class="col-span-2 md:col-span-1">
        <FormKit
          label="Straatnaam"
          name="street"
          :disabled="hasAutofill"
        />
      </div>
      <div class="col-span-2 md:col-span-1">
        <FormKit
          label="Plaatsnaam"
          name="city"
          :disabled="hasAutofill"
        />
      </div>
    </div>
  </FormKit>
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';
import addressAutofillQuery from '~/graphql/queries/addressAutofill.gql';

type Data = {
  zipcode: string
  house_number: string
  house_number_suffix: string
  street: string
  city: string
  country: string
} | undefined;


const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const addressForm = ref({});

const formData = computed(() => props.context.value ?? {});
const hasAutofill = ref(false);
const canAutofill = computed(() => {
  if (!formData.value?.zipcode?.length || !formData.value?.house_number?.length) {
    return false;
  }

  return formData.value?.zipcode?.length && formData.value?.house_number?.length;
});

watch(formData, (newValue) => {
  if (newValue?.house_number) {
    addressForm.value = {...formData.value};
  }
}, {immediate: true});

// Address hint
watchDebounced(
  canAutofill,
  () => {
    if (hasAutofill.value) {
      return;
    }

    if (formData.value?.zipcode?.length && formData.value?.house_number?.length) {
      load(null, {
        house_number: formData.value?.house_number ? `${formData.value?.house_number} ${formData.value?.house_number_addition ?? ''}` : '',
        zipcode: formData.value?.zipcode ?? '',
      });
    }
  },
  {debounce: 1000},
);

const {load, onResult} = useLazyQuery(addressAutofillQuery);

onResult(({loading, data}) => {
  if (loading) {
    return;
  }

  const {street, city} = data.addressAutofill;

  if (street && city) {
    hasAutofill.value = true;
  }

  addressForm.value = {
    ...addressForm.value,
    street: street,
    city: city,
  };

  handleInput({
    ...formData.value,
    street: street,
    city: city,
  });
});

function handleInput(value: Data) {
  props.context.node.input(isValid(value) ? value : {});
}

function isValid(value: Data) {
  if (!props.context.attrs.required) {
    return true;
  }

  if (!value) {
    return false;
  }

  return Object.entries(value).every(([key, value]) => key === 'house_number_suffix' || value?.length > 0);
}
</script>
